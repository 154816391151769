import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import Button from '~/shared/buttons/button/button.component';
import { Svg } from '~/shared/svg/svg.component';
import useImpersonateMode from '~/services/user/use-impersonate-mode';
import useTranslations from '~/shared/hooks/use-translations.hook';
import { useIsImpersonated } from '~/libs/use-user';
import styles from './impersonate-banner.module.scss';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { useCurrentBasket } from '~/libs/queries/basket';

const ImpersonateBanner = () => {
    const { deactivate } = useImpersonateMode();
    const isImpersonated = useIsImpersonated();
    const { activeProfile, isCustomer } = useActiveUser();
    const { data: basket } = useCurrentBasket();

    const translate = useTranslations();
    const customer = activeProfile?.customer;

    return (
        <AnimatePresence initial={false}>
            {isImpersonated && customer && isCustomer && (
                <motion.div
                    className={styles.banner}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ type: 'spring', stiffness: 600, damping: 50 }}
                >
                    <div className={styles.inner}>
                        <div className={styles.userInfo}>
                            <div className={styles.userInfoTop}>
                                <p className={styles.info}>
                                    <strong className={styles.title}>{translate('impersonate.banner.department', 'Afdeling')}</strong>
                                    <br />
                                    <span className={styles.value}>{customer?.primaryDepartmentId}</span>
                                </p>

                                {customer?.name && (
                                    <p className={styles.info}>
                                        <strong className={styles.title}>{translate('impersonate.banner.customerName', 'Kundenavn')}</strong>
                                        <br />
                                        <strong>{customer?.name}</strong>
                                    </p>
                                )}

                                {customer?.address || customer?.zipCodeAndCity ? (
                                    <p className={clsx(styles.info, styles.infoAddress)}>
                                        <strong className={styles.title}>{translate('impersonate.banner.customerAddress', 'Adresse')}</strong>
                                        <br />
                                        {customer?.address}
                                        {customer?.address && customer?.zipCodeAndCity && ', '}
                                        {customer?.zipCodeAndCity}
                                    </p>
                                ) : null}

                                {customer?.phoneNumber ? (
                                    <p className={clsx(styles.info, styles.infoPhone)}>
                                        <strong className={styles.title}>{translate('impersonate.banner.customerPhone', 'Kunde TLF.')}</strong>
                                        <br />
                                        {customer?.phoneNumber}
                                    </p>
                                ) : null}

                                {customer?.internalInformation ? (
                                    <p className={clsx(styles.info, styles.internalInformation)}>
                                        <strong className={styles.title}>
                                            {translate('impersonate.banner.internalInformation', 'Intern information')}
                                        </strong>
                                        <br />
                                        {customer?.internalInformation}
                                    </p>
                                ) : null}
                            </div>

                            {customer.remark1 || customer.remark2 ? (
                                <div className={styles.commentsSection}>
                                    <strong className={styles.title}>{translate('impersonate.banner.comments', 'Bemærkninger')}</strong>
                                    <br />
                                    <div className={styles.comments}>
                                        <div className={styles.comment}>{customer.remark1}</div>
                                        <div className={styles.comment}>{customer.remark2}</div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <Button
                            icon={<Svg name="close" />}
                            buttonStyle="secondary"
                            onClick={async (event) => {
                                event.preventDefault();
                                await deactivate(basket?.id);
                            }}
                        >
                            {translate('common.exit', 'Afslut')}
                        </Button>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ImpersonateBanner;
