import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import Button from '~/shared/buttons/button/button.component';
import ErrorBox from '~/shared/error-box/error-box';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import styles from './departments-list.module.scss';
import { Collapse } from '~/shared/collapse';
import { Svg } from '~/shared/svg';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import { useDepartmentsByRegions } from '~/libs/queries/users/hooks/use-departments-by-regions';

interface IProps {
    onClose: () => any;
}

const DepartmentsList: FC<IProps> = ({ onClose }) => {
    const { data, isLoading, isError } = useDepartmentsByRegions();
    const dispatch = useLayoutDispatch();
    const { selectedDepartment } = useLayoutState();
    const translate = useTranslations();
    const { activeProfile } = useActiveUser();
    const [selectedDistrict, setSelectedDistrict] = useState<number | null>(null);

    useEffect(() => {
        const selectedId = selectedDepartment?.id || activeProfile?.customer?.deliveryDepartmentId;
        data?.forEach((district) => {
            const match = district?.departments?.find((d) => d.id === selectedId);
            if (match) {
                setSelectedDistrict(district.districtId);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (isLoading) return <Loader />;

    if (isError) {
        return <ErrorBox>{translate('common.somethingWentWrong', 'Der gik noget galt.')}</ErrorBox>;
    }

    return (
        <div className={styles.districtsList}>
            {data?.map((district) => {
                const isOpen = selectedDistrict === district.districtId;
                return (
                    <div key={district.districtId} className={styles.district}>
                        <button
                            className={styles.districtButton}
                            type="button"
                            onClick={() => {
                                if (district.districtId === selectedDistrict) {
                                    setSelectedDistrict(null);
                                } else {
                                    setSelectedDistrict(district.districtId);
                                }
                            }}
                            disabled={district.districtId === selectedDepartment?.id}
                        >
                            {district.districtName}
                            <Svg
                                thick
                                className={clsx(styles.btnIcon, {
                                    [styles.btnIconOpen]: isOpen,
                                })}
                                name="plus"
                            />
                        </button>

                        <Collapse isOpen={isOpen}>
                            <ul className={styles.departmentsList}>
                                {district?.departments?.map((department) => (
                                    <li key={department.id}>
                                        <Button
                                            onClick={() => {
                                                dispatch({
                                                    type: LayoutActionTypes.SetSelectedDepartment,
                                                    payload: {
                                                        id: department.id,
                                                        title: department.description as string,
                                                    },
                                                });
                                                onClose();
                                            }}
                                            disabled={department.id === selectedDepartment?.id}
                                            buttonSize="small"
                                            buttonStyle="clean"
                                        >
                                            {department.description}
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        </Collapse>
                    </div>
                );
            })}
        </div>
    );
};

export default DepartmentsList;
