import React, { FC, useEffect, useRef } from 'react';

import useTranslations from '~/shared/hooks/use-translations.hook';
import useImpersonateMode from '~/services/user/use-impersonate-mode';
import useActiveUser from '~/libs/queries/users/hooks/useActiveUser';
import Loader from '~/shared/loader/loader.component';

import RawHtml from '~/page-elements/raw-html/raw-html.component';
import Button from '~/shared/buttons/button/button.component';
import { LayoutActionTypes, useLayoutDispatch, useLayoutState } from '~/context/layout.context';
import { useCurrentBasket, useDeleteBasket, useDeleteBasketHandlers, useSaveBasket, useSaveBasketHandlers } from '~/libs/queries/basket';

import styles from './modal-content.module.scss';

interface IProps {
    closeModal?: () => void;
    focus?: boolean;
}

const ModalContent: FC<IProps> = ({ closeModal, focus }) => {
    const translate = useTranslations();
    const dispatch = useLayoutDispatch();

    const { activeProfile } = useActiveUser();
    const { activate } = useImpersonateMode();
    const { selectedDepartment, customerOrBasketTransfer } = useLayoutState();

    const { data: basket } = useCurrentBasket();
    const { mutateAsync: saveBasketAsync, isLoading: isSavingBasket } = useSaveBasket();
    const { mutate: deleteBasket, isLoading: isDeletingBasket } = useDeleteBasket();

    const {
        handleError: handleDeleteBasketError,
        handleSuccess: handleDeleteBasketSuccess,
        handleSettled: handleDeleteBasketSettled,
    } = useDeleteBasketHandlers();
    const {
        handleError: handleSaveBasketError,
        handleSuccess: handleSaveBasketSuccess,
        handleSettled: handleSaveBasketSettled,
    } = useSaveBasketHandlers();

    const { customerId = '', basketId } = customerOrBasketTransfer || {};

    const ref = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (focus) {
            ref?.current?.focus();
        }
    });

    const clear = () => {
        dispatch({
            type: LayoutActionTypes.Impersonate,
            payload: null,
        });

        if (customerOrBasketTransfer?.callBack) {
            customerOrBasketTransfer.callBack();
        }

        closeModal?.();
    };

    const saveAndSwitch = async () => {
        if (!basket?.id) {
            return;
        }

        await saveBasketAsync(
            {
                basketId: basket?.id,
                departmentId: selectedDepartment?.id,
                status: 'manual',
            },
            {
                onSuccess: handleSaveBasketSuccess,
                onError: handleSaveBasketError,
                onSettled: handleSaveBasketSettled,
            },
        );

        await activate(customerId);

        clear();
    };
    const deleteAndSwitch = async () => {
        if (!basket?.id) {
            console.error('Current basket cannot be deleted. Basket id is required.');
            return;
        }

        deleteBasket(
            { basketId: basket?.id },
            {
                onSuccess: handleDeleteBasketSuccess,
                onError: handleDeleteBasketError,
                onSettled: handleDeleteBasketSettled,
            },
        );

        await activate(customerId);

        clear();
    };

    const transferBasket = async () => {
        await activate(customerId as string, basket?.id);

        clear();
    };

    const isLoading = isSavingBasket || isDeletingBasket;

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <RawHtml
                    content={translate(
                        'impersonate.openBasketWarnMessage',
                        '<p>Du er ved at overskrive en åben kurv. Vil du gemme den nuværende kurv for <strong>[currentCustomerName]</strong?<br />Du kan efterfølgende finde kurven ved at logge ind som kunden og åbne gemt kurv</p>',
                    ).replace('[currentCustomerName]', activeProfile?.displayName)}
                />
            )}
            <div className={styles.buttons}>
                <Button ref={ref} disabled={isLoading} onClick={saveAndSwitch}>
                    {translate('impersonate.saveAndSwitchButton', 'Gem kurv og og åbn ny kunde')}
                </Button>
                <Button disabled={isLoading} onClick={deleteAndSwitch} buttonStyle="secondary">
                    {translate('impersonate.deleteAndSwitchButton', 'Slet gammel kurv')}
                </Button>
                {!basketId && (
                    <Button disabled={isLoading} onClick={transferBasket} buttonStyle="secondary">
                        {translate('impersonate.switchBasketBtn', 'Behold kurv, men skift kunde')}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ModalContent;
