import { Suspense, lazy, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useProductDetails } from '~/libs/queries/products/hooks/use-product-details';
import { Orders as OrdersTypes } from '~/models/orders.d';
import { Vehicles as VehicleTypes } from '~/models/vehicles.d';
import { StockStatusWithStockQuery } from '~/shared/stock-status/stock-status.component';
import { Svg } from '~/shared/svg';
import ProductImageTitleBrand from 'shared/product-image-title-brand/product-image-title-brand.component';
import Checkbox from '~/shared/form-elements/checkbox/checkbox.component';
import useTranslations from '~/shared/hooks/use-translations.hook';
import Loader from '~/shared/loader/loader.component';
import Skeleton from '~/shared/skeleton';
import Text from '~/shared/text/text.component';
import styles from './styled.module.scss';

interface IInvoiceItemProps {
    isLoadingItemsFit: boolean;
    item: OrdersTypes.IVehicleInvoiceDetailItem;
    changeHandler?: (item: OrdersTypes.IVehicleInvoiceDetailItem) => void;
    isSelected?: boolean;
    itemFit?: boolean;
    licensePlate?: VehicleTypes.ILicensePlate;
    isInStock?: boolean;
}

const OtherVariants = lazy(() => import('~/page-elements/product-detail-card/other-variants/other-variants.component'));

export default function InvoiceItem({ item, changeHandler, isSelected, itemFit, licensePlate, isInStock, isLoadingItemsFit }: IInvoiceItemProps) {
    enum Content {
        OtherVariantsComponent = 'OtherVariants',
    }

    const translate = useTranslations();
    const alreadyCredited = item?.possibleToCreditQuantity === 0;
    const { data: product, isLoading } = useProductDetails(item?.productId);
    const [selectedComponent, setSelectedComponent] = useState<Content | null | undefined>();

    const contentButtons = [
        {
            id: 1,
            shouldRender: itemFit && (product?.variants?.length as number) > 0,
            label: `${translate('product.otherVariants', 'Andre varianter')} (${product?.variants?.length})`,
            content: Content.OtherVariantsComponent,
        },
    ];
    const selectedButton = contentButtons.find((btn) => btn.content === selectedComponent);
    // We add switch statement in case FTZ decides to add more accordion items
    const renderComponent = () => {
        switch (selectedComponent) {
            case Content.OtherVariantsComponent:
                return (
                    <Suspense fallback={<Loader />}>
                        <div className={styles.otherVariantsContent}>
                            <OtherVariants otherProductVariants={product?.variants} isSmall />
                        </div>
                    </Suspense>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        if (!isInStock) {
            return setSelectedComponent(() => Content.OtherVariantsComponent);
        }
        setSelectedComponent(() => null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemFit, isInStock]);

    return (
        <>
            {!isLoadingItemsFit && !itemFit ? (
                <Text color="errorColor">
                    {translate('vehicle.productNotFitVehicle', 'Følgende produkt passer ikke længere på reg. nr.[regNumber]').replace(
                        '[regNumber]',
                        licensePlate?.number ? licensePlate.number : '',
                    )}
                </Text>
            ) : null}
            <div className={styles.item}>
                <div
                    className={clsx(styles.itemRow, {
                        [styles.alreadyCredited]: alreadyCredited || !itemFit,
                        [styles.noProduct]: !item?.productId,
                    })}
                >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    <Checkbox onChange={() => changeHandler(item)} checked={isSelected} disabled={!itemFit || isLoadingItemsFit} />
                    <ProductImageTitleBrand className={styles.product} image={item.image} brand={item.brand} name={item.title} />
                    <Text textStyle="bodySmall" secondary fontWeight="semiBold">
                        {item.quantity} {translate('common.piece', 'stk.')}
                    </Text>
                    <div className={styles.stockPriceWrapper}>
                        <StockStatusWithStockQuery itemNo={item?.itemId} />
                    </div>
                </div>
                {!item?.productId ? null : isLoading ? (
                    <Skeleton
                        style={{
                            width: '100%',
                            height: '200px',
                        }}
                    />
                ) : (
                    <div className={styles.accordion}>
                        {contentButtons
                            .filter((btn) => btn.shouldRender)
                            .map((btn) => {
                                const selected = btn.content === selectedComponent;

                                return (
                                    <button
                                        style={{ order: btn.id }}
                                        className={clsx(styles.accordionButton, {
                                            [styles.isOpen]: selected,
                                        })}
                                        key={btn.id}
                                        type="button"
                                        onClick={() => {
                                            setSelectedComponent(selected ? null : btn.content);
                                        }}
                                    >
                                        <span>{btn?.label}</span>
                                        <Svg name="chevron-right" thick className={styles.accordionIcon} />
                                    </button>
                                );
                            })}

                        {selectedComponent && (
                            <div className={styles.bottomSection} style={{ order: selectedButton?.id || 1 }}>
                                {renderComponent()}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
